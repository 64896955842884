import React from "react";

const LoginHelpModalDataSpanish = () => {
  return (
    <>
      <p>
        Para iniciar Banca en Linea tiene que obtener un usuario y contraseña. Siga usando su usiario y contraseña en
        esta actualizasion. Es possible que tenga que verificar alguna informacion antes de su inicio.
      </p>
      <p className="lead">Reglas de Usuario</p>
      <p>
        Su Usuario no puede ser mas de 6 caracteres. No puede usar numero de cuenta o seguro social o caracteres
        especiales:
        <code>` # % & * ( ) = {} / \ ; : ' " ,</code>
      </p>
      <p className="lead">Reglas de Contraseña</p>
      <p>
        Su contraseña debe de ser enter 8 a 32 Caracteres. Incluya 1 letra mayuscula y menuscula, tabien un numero y
        signo especial como (!,$,@,?):
        <code>. , # @ : ? ! / ( ) $ \ -</code>
      </p>
      <p>No Puede usar contraseñas antepasadas</p>
      <p className="lead">Necesito ayuda?</p>
      <p>
        Nuestro servicio al cliente esta disponible para contestar cualquier pregunta. Yame al{" "}
        <a href="tel:800-324-9375">800-324-9375</a>.
      </p>
    </>
  );
};

export default LoginHelpModalDataSpanish;
